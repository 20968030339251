 <template>
 <div class="log">
	<div class="login">
		<div class="login-width">
			<div class="login-center-width">
				<div class="login-center-width-img">
          <Isvg icon-class="logo-text" class-name="item-logo" />
        </div>
				<div class="retrievePassword-center-width-pinkF">如果您之前使用第三方账号登录，因没有绑定手机号，无法直接找回密码，需要通过第三方账号登录！</div>

				<ValidationObserver ref="findPasswordFormStepOne">
					<div class="retrievePassword-center1">
						<ValidationProvider name="手机号码" rules="required|mobile" v-slot="{ errors }" mode="passive">
							<input class="input-400 login-input-heightbot login-input-block" type="text" v-model="mobile" placeholder="请输入手机号码" autofocus="autofocus" />

							<!-- 手机号错误的提示文字 -->

							<p>{{ errors[0] }}</p>
						</ValidationProvider>
					</div>
					<div class="retrievePassword-center2">
						<ValidationProvider name="验证码" rules="required" v-slot="{ errors }" mode="passive">
							<div class="retrievePassword-center2-yanzhengma">
								<input type="text" v-model="code" placeholder="请输入验证码" class="input-250 login-input-heightbot" />
								<button :class="'button-blue-150' + btnStyle" @click="getSendCode(this)" ref="buttonNo">{{ codeContent }}</button>
							</div>

							<p>{{ errors[0] }}</p>
						</ValidationProvider>
					</div>
				</ValidationObserver>
				<div class="retrievePassword-button"><el-button :loading="retrievePassword" type="button" class="button-blue-400" @click="nextStep()">下一步</el-button></div>
				<p class="register-goLogin">
					还没有账号？
					<a href="javascript:void(0);" @click="$router.push('/register')">立即注册</a>
				</p>
			</div>
		</div>
		<p class="register-beihao fs smallsize-font">© 2022 gogowork.cn All Rights Reserved 京IPC备：2021019219号-1</p>
	</div>
	</div>
</template>

<script>
import { requestFindPasswordOne, requestSendCode } from '@/api/index';
import LoginHeader from "@/components/LoginHeader"
export default {
	name: 'retrievePassword',
	components:{
		LoginHeader
	},
	data() {
		return {
			mobile: '',
			code: '',
			wait: 60,
			codeContent: '发送验证码',
			canClick: true,
			btnStyle: '',
			btnStyleNo: '',
			retrievePassword:false
		};
	},
  created() {
    this.keyDown()
  },
  methods: {
		async toFind() {
			let data = {
				account: this.mobile,
				code: this.code,
				code_type: 3 // 找回密码
			};
			const res = await requestFindPasswordOne(data);
			if (res.statusCode == 200) {
				this.retrievePassword = true
				localStorage.setItem('find_password_token', res.data.token);
				this.$router.push('/reset');
			} else {
				this.$notify({
					message: res.message,
					type: 'warn'
				});
			}
		},
		getSendCode(obj) {
			if (this.canClick) {
				// 单独验证手机号
				this.$validate(this.mobile, 'required|mobile', {
					name: '手机号'
				}).then(result => {
					if (result.valid) {
						this.canClick = false;
						// 开始倒计时
						this.btnStyle = ' disabled';
						this.btnStyleNo = 'cursor: not-allowed;';
						this.codeContent = this.wait + '秒';
						this.$refs.buttonNo.style = 'cursor: not-allowed';
						let clock = window.setInterval(() => {
							this.wait--;
							this.codeContent = this.wait + '秒';
							if (this.wait < 0) {
								//当倒计时小于0时清除定时器
								window.clearInterval(clock);
								this.codeContent = '重新发送验证码';
								this.wait = 60;
								this.canClick = true;
								this.btnStyle = '';
								this.$refs.buttonNo.style = 'cursor:pointer';
							}
						}, 1000);
						this.sendCode();
					} else {
						this.$notify({
							message: '手机号格式错误',
							type: 'error'
						});
					}
				});
			}
		},
		async sendCode() {
			if (!this.checkMobile(this.mobile)) {
				return;
			}
			const res = await requestSendCode({ account: this.mobile, code_type: 3 });
			if (res.statusCode == 200) {
				this.$notify({
					message: res.data,
					type: 'success'
				});
			} else {
				this.$notify({
					message: res.message,
					type: 'warning'
				});
			}
		},
		checkMobile(mobile) {
			if (mobile == '') {
				this.$notify({
					message: '请填写手机号码',
					type: 'warn'
				});
				return false;
			}
			// 必须是1开头，第二位数字可以是0-9任意一个，总长为11
			let reg = /^1([0-9])\d{9}$/;
			if (reg.test(mobile)) {
				return true;
			} else {
				this.$notify({
					group: 'warnMsg',
					text: '手机号码格式错误',
					type: 'warn'
				});
				return false;
			}
		},
		nextStep() {
			this.$refs.findPasswordFormStepOne.validate().then(success => {
				if (!success) {
					return;
				} else {
					this.toFind();
				}
			});
		},
    //按钮监听键盘
    keyDown () {
      let that = this
      //监听键盘按钮
      document.onkeydown = function (event) {
        var e = event || window.event;
        var keyCode = e.keyCode || e.which;
        switch (keyCode) {
          case 13://enter
            that.nextStep()
            break;
          default:
            break;
        }
      }
    }
	}
};
</script>

<style lang="less" scoped>
.item-logo{
  width: 8em;
  height: 3em;
  margin-top: 20px;
}
.login{
  padding-top: 60px;
}
.retrievePassword-center-width-pinkF {
	margin-top: 40px;
	font-size: 12px;
	width: 276px;
	color: #575757;
	line-height: 22px;
}
.retrievePassword-center1 {
	width: 300px;
	margin: 21px 0 20px 0;
	p {
		margin-left: 10px;
		font-size: 10px;
		font-weight: 400;
		color: #e4007f;
	}
}
.retrievePassword-center2 {
	width: 300px;
	margin-bottom: 14px;
	.retrievePassword-center2-yanzhengma {
		display: flex;
		justify-content: space-between;
	}
	p {
		margin-left: 10px;
		font-size: 10px;
		font-weight: 400;
		color: #e4007f;
	}
}
/deep/.el-button {
	border-radius: none;
}
.button-blue-400{
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	/deep/span{
		position: relative;
		top:2px
	}
}
</style>
