<template>
  <div class="login-header">
    <div class="login-header-center">
    <div class="login-header-left">
      <a href="javascript:void(0);" @click="$router.push('/')">
      <img src="@/icons/svg/shop/logo-108X24.svg"
      /></a>
      <span>自由职业者的家</span>
    </div>
    <div class="login-header-right">
      <a href="/">返回首页</a>
    </div>
    <div class="mb-40"></div>
  </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
  .login-header{
    height: 64px;
    width: 100%;
    background-color: #fff;
    display: flex;
    .login-header-center{
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      position: relative;
    .login-header-left{
      display: flex;
      align-items: center;
      a{
        img{
          width: 108px;
          height: 24px;
          object-fit: fill;
        }
      }
      span{
        line-height: 24px;
        border-left: 1px solid #b5b5b5;
        margin-left: 20px;
        padding:0 20px;
      }
    }
    .login-header-right{
      position: absolute;
      right: 0;
      a{
        color: #000000;
      }
    }
  }
  }
</style>
